import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ROUTES } from '@environments/routes/api-routes';
import { GSheetReturnFormat, IRoadmapItem } from '@shared/roadmap/interfaces/roadmap.interface';
import { Observable, map } from 'rxjs';

@Injectable()
export class RoadmapService {
  constructor(private __http: HttpClient) {}

  public getRoadmap(): Observable<IRoadmapItem[]> {
    return this.__http.get<GSheetReturnFormat>(API_ROUTES.roadmap()).pipe(
      map((roadmap) => roadmap.values),
      map((roadmap) =>
        roadmap
          .map(
            (item) =>
              ({
                title: item[0],
                description: item[1],
                date: item[2],
                status: item[3]
              } as IRoadmapItem)
          )
          .sort((a, b) => (a.date > b.date ? 1 : -1))
      )
    );
  }

  public getReleaseNotes(): Observable<IRoadmapItem[]> {
    return this.__http.get<GSheetReturnFormat>(API_ROUTES.releaseNotes()).pipe(
      map((roadmap) => roadmap.values),
      map((roadmap) =>
        roadmap
          .map(
            (item) =>
              ({
                title: item[0],
                description: item[1],
                date: item[2]
              } as IRoadmapItem)
          )
          .sort((a, b) => (a.date > b.date ? 1 : -1))
      )
    );
  }
}
